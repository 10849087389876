<template>
	<div data-route class="page">
		<div class="page__info">
			<div class="page__info--title">
				Create an account
			</div>
		</div>

		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="First name"
					type="text"
					rules="required"
					v-model="profile.firstName"
				/>
				<input-field
					name="Last name"
					type="text"
					rules="required"
					v-model="profile.lastName"
				/>
				<input-field
					name="Email address"
					type="email"
					autocomplete="username"
					rules="required"
					v-model="profile.email"
				/>
				<select-field
					name="Your experience level"
					rules="required"
					:options="experienceLevelOptions.slice(0,8)"
					v-model="profile.experienceLevel"
				/>
				<select-field
					name="Your preferred bidding system"
					rules="required"
					:options="biddingSystemOptions"
					v-model="profile.biddingSystem"
				/>
				<input-field
					name="Password"
					type="password"
					autocomplete="new-password"
					rules="required|min:8"
					v-model="profile.password"
				/>
				<input-field
					name="Confirm password"
					type="password"
					autocomplete="new-password"
					rules="required|confirmed:password"
					v-model="profile.passwordConfirm"
				/>
				<checkbox-field
					name="I would like to be contacted by Bridge128 to hear about updates and news"
					v-model="profile.gdprConsent"
				/>
				<actions
					:actions="actions"
					:isWaiting="isWaiting"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublicOnly": true
		}
	}
</route>

<script>
	import InputField            from '@/components/forms/InputField';
	import SelectField           from '@/components/forms/SelectField';
	import CheckboxField         from '@/components/forms/CheckboxField';
	import Actions               from '@/components/ui/Actions';
	import actionClick           from '@/mixins/actionClick';
	import invalidFormMessage    from '@/mixins/invalidFormMessage';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems }    from '@/consts';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Create new account'
		},
		components: {
			InputField,
			SelectField,
			CheckboxField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			isWaiting: undefined,
			experienceLevelOptions: experienceLevels,
			biddingSystemOptions: biddingSystems,
			profile: {
				email:            '',
				firstName:        '',
				lastName:         '',
				experienceLevel:  '',
				biddingSystem:    '',
				password:         '',
				passwordConfirm:  '',
				timezone:         undefined,
				gdprConsent:      false
			},
			actions: {
				primary: [
					{
						text: 'Create',
						type: 'submit',
						btnId: 'submit'
					}
				],
				secondary: [
					{
						text: 'Cancel',
						route: '/login'
					}
				]
			}
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/create-account',
						text: 'Create an account'
					}
				];
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				this.isWaiting = 'submit';
				await this.$store.dispatch('auth/createAccount', {
					profile: {
						...this.profile,
						timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/London'
					}
				});
				this.isWaiting = undefined;
			}
		}
	};

</script>

<style lang="scss" scoped>

.page {
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      font-size: 46px;
      margin-bottom: 24px;
    }
  }
}

</style>
